<template>
  <div>
    <v-container fluid>

      <div class="main">

        <v-card class="shadow">
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col v-if="can('supplier')" class="d-lg-block d-none" cols="12" lg="3"
                     style="border-right: 1px solid #eaeaea">
                <Menu/>
              </v-col>

              <v-col v-if="can('store')"
                     class="d-none d-lg-block"
                     cols="12" lg="3" style="border-right: 1px solid #eaeaea">
                <StoreCard/>
              </v-col>

              <v-col cols="12" lg="9">

                <v-card flat>

                  <v-overlay :value="isLoading"
                             absolute
                             color="white"
                             opacity="0.9">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="50"
                    ></v-progress-circular>

                  </v-overlay>

                  <v-card-title>

                    Scanner

                    <v-spacer/>

                    <v-btn :to="'/partner-vouchers/store/'+$route.params.id"
                           depressed
                           exact
                           fab
                           small>
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                  </v-card-title>

                  <v-divider/>

                  <v-card-text>

                    <v-alert v-model="isError" border="left" dismissible type="error">
                      {{ errorMessage }}
                    </v-alert>

                    <v-tabs
                        v-model="tab"
                        active-class="gifty"
                        background-color="transparent"
                        centered
                        color="white"
                        hide-slider
                        icons-and-text
                    >
                      <v-tab>
                        Manuel
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-tab>

                      <v-tab>
                        Scan camera
                        <v-icon>mdi-camera-outline</v-icon>
                      </v-tab>

                    </v-tabs>

                    <v-tabs-items v-model="tab">


                      <v-tab-item>
                        <v-card flat>
                          <v-card-text class="pa-lg-10 pa-0">

                            <p class="font-weight-medium pt-4 pt-lg-0">
                              Code Voucher <span class="red--text">*</span>
                            </p>

                            <v-textarea v-model="code"
                                        clearable
                                        outlined
                                        clear-icon="mdi-close-circle"
                                        hide-details
                                        placeholder="Veuillez saisir le code de voucher ici..."
                                        rows="2">

                            </v-textarea>

                            <div class="d-flex mt-5">

                              <v-spacer/>

                              <v-btn :dark="!!code"
                                     :disabled="!code"
                                     color="gifty"
                                     depressed
                                     @click="scan">
                                <v-icon left>mdi-rotate-left</v-icon>
                                Vérifier
                              </v-btn>

                            </div>

                          </v-card-text>
                        </v-card>
                      </v-tab-item>


                      <v-tab-item>
                        <v-card flat>
                          <v-card-text class="pa-lg-10 pa-0">
                            <qrcode-stream @decode="onDecode"/>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>


                    </v-tabs-items>


                  </v-card-text>

                </v-card>
              </v-col>

            </v-row>
          </v-card-text>

        </v-card>

      </div>


      <v-dialog v-if="result"
                v-model="dialog"
                persistent
                scrollable
                width="500">
        <v-card :disabled="btnLoading" :loading="btnLoading">

          <v-card-title>
            <v-spacer/>
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-card-title>

          <v-card-text class="pa-8">

            <div v-if="success" class="text-center">

              <lottie :options="defaultOptions" :height="300" :width="300"/>

              <h1 class="pb-5 gifty--text">
                Succès !
              </h1>

              <p>
                Cette opération a été enregistrée avec succès.
              </p>

              <div class="d-flex mt-10 justify-space-between">
                <v-btn color="gifty"
                       text
                       exact
                       class="rounded-lg"
                       :to="'/partner-vouchers/store/' + $route.params.id">
                  <v-icon left>mdi-arrow-left</v-icon>
                  Retour
                </v-btn>
                <v-spacer/>
                <v-btn color="gifty" depressed dark class="rounded-lg"
                       @click="[dialog = false,success = false,code=null]">
                  <v-icon left>mdi-plus</v-icon>
                  Nouveau
                </v-btn>
              </div>

            </div>

            <div v-else class="text-center">


              <v-avatar color="gifty" size="80">
                <v-icon dark size="40">mdi-sticker-check-outline</v-icon>
              </v-avatar>

              <h1 class="py-8 gifty--text">{{ CurrencyFormatting(result.amount) + '.00' }} DZD</h1>

              <p>
                <span class="font-weight-medium">Fournisseur :</span>
                {{ result.supplier.name }}
              </p>

              <p>
                <span class="font-weight-medium">Date d'expération :</span>
                {{ getHumanDate(result.expiration_date) }}
              </p>

              <div class="d-flex mt-10 justify-space-between">
                <v-btn color="red" class="rounded-lg" text @click="dialog=false">
                  <v-icon left>mdi-close</v-icon>
                  Fermer
                </v-btn>

                <v-spacer/>
                <v-btn :loading="btnLoading"
                       color="gifty"
                       class="rounded-lg"
                       dark
                       depressed
                       @click="save">
                  <v-icon left>mdi-cloud-refresh</v-icon>
                  Converter
                </v-btn>
              </div>

            </div>

          </v-card-text>
        </v-card>
      </v-dialog>


    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import Menu from "@/views/partner_vouchers/components/Menu";
import StoreCard from "@/views/partner_vouchers/components/StoreCard";
import {QrcodeStream} from 'vue-qrcode-reader'
import moment from "moment";
import lottie from 'vue-lottie';
import successIcon from '@/assets/success-lottie.json';

export default {
  components: {
    Menu,
    lottie,
    StoreCard,
    QrcodeStream
  },
  data() {
    return {
      tab: null,
      isLoading: false,
      btnLoading: false,
      code: null,

      isError: false,
      errorMessage: null,
      dialog: false,
      result: null,
      success: false,
      defaultOptions: {
        animationData: successIcon,
        loop: false,
        autoplay: true,
      },
    };
  },
  methods: {
    scan() {
      this.success = false
      this.isLoading = true
      HTTP.get('/v1/partner-vouchers/scan', {
        params: {
          code: this.code,
          store_id: this.$route.params.id,
        }
      }).then(res => {
        this.result = res.data.data
        this.isLoading = false
        this.dialog = true
      }).catch(err => {
        this.isLoading = false
        if (err && err.response && err.response.data.message) {
          this.isError = true
          this.errorMessage = err.response.data.message
        }
        if (err.response.status === 429) {
          this.isError = true
          this.errorMessage = 'Vous avez dépassé le nombre de tentatives autorisées!'
        }
        console.log(err)
      })
    },
    save() {
      this.btnLoading = true
      let data = {
        store_id: this.$route.params.id,
        code: this.code,
      }
      HTTP.post('/v1/partner-vouchers/store', data).then(() => {
        this.btnLoading = false
        this.success = true
        this.code = null
      }).catch(err => {
        this.btnLoading = false
        this.dialog = false
        if (err && err.response && err.response.data.message) {
          this.isError = true
          this.errorMessage = err.response.data.message
        }
        console.log(err)
      })
    },

    getHumanDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },

    onDecode(result) {
      this.code = result
      this.scan()
    },
  }
};
</script>

<style scoped></style>
